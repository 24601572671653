// app background color
.c-app {
  background-color: $light;
}

.c-sidebar {
  background-color: $primary;
}

// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  font-size: 0.85rem;
  /* white-space: nowrap; */
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}

.form-group label {
  font-weight: 500;
}

.card {
  border: none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-dialog {
  @media only screen and (min-width: 576px) {
    max-width: 50rem;
  }
}
