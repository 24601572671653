// Variable overrides
$primary: #475447;
$secondary: #ddb031;
$light: #f9fafb;

// Dashboard
.c-header-email {
  margin: auto;
  margin-left: 0.5em;
  max-width: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bg-light-grey {
  background: #f1f1f1;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.object-fit-cover {
  object-fit: cover;
}
