// Here you can add other styles
.summary-title {
  border-bottom: 2px solid #321fdb;
  padding: 0.3em 0;
  font-size: 0.85em;
  line-height: 0.85rem;
}

.font-danger {
  color: #ff4141;
  font-weight: bold;
}

.font-warning {
  color: #ee7421;
  font-weight: bold;
}

.font-purple {
  color: #7b0180;
  font-weight: bold;
}

.font-success {
  color: #32d769;
  font-weight: bold;
}

.font-pending {
  color: #39f;
  font-weight: bold;
}

.font-blue {
  color: #321fdb;
  font-weight: bold;
}

.rotate-180 {
  transform: rotate(180deg);
}

.icon-indicator {
  font-size: 0.5rem !important;
  height: 0.5rem !important;
  width: 0.5rem !important;
  margin: 0 0.4em 0.3em 0;
}

.btn-orange,
.btn-orange:hover {
  color: white;
  background-color: #ee7421;
  border-color: #ee7421;
}

.h-fit {
  height: fit-content;
}

.break-word {
  word-break: break-all;
}

.no-underline {
  text-decoration: none;
}

// full screen modal
.full-screen {
  .modal-dialog {
    max-width: 95%;
  }
}

// news dropdown-menu
.news-dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
  width: 25rem;
  @media only screen and (max-width: 767px) {
    width: 100vw;
  }
}

// login
.img-login {
  @media only screen and (max-width: 767px) {
    height: 18rem;
  }
}

// sale dot indicator
.dot-indicator {
  width: 8px;
  height: 8px;
  top: 0;
}

// duration picker on dashboard
.duration-picker {
  .duration-group {
    margin-right: 2rem;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    .duration-group {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    .button-group {
      align-self: flex-end;
    }
  }
}

// Sale details accordion
.accordion-header {
  padding: 1rem 1.25rem !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accordion-header-focus {
  background-color: #4754471a !important;
  color: $primary !important;
  border: 0.2em solid #47544733 !important;
}

// ASYNC WRAPPER INVALID BORDER
.async-select-wrapper {
  &.invalid {
    border: 1px solid #e55353;
    border-radius: 4px;
  }
}
